import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faBan, faLock, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  faBan = faBan;
  faLock = faLock;
  faPlus = faPlus;
  ts: any;
  ts_source = {
    en: {
      cart: "Cart",
      create_more_maps: "Create More Maps",
      checkout: "Checkout",
      subtotal: "Subtotal",
      free_shipping: "Shipping - FREE",
      item_total: "Item Total",
      remove: "Remove",
      quantity: "Quantity",
      title: "Title",
      subtitle: "Subtitle",
      size: "Size",
      style: "Style",
      creation_id: "Creation ID",
      map_preview_loading: "Your Map Preview is loading"
    },
    fr: {
      cart: "Panier",
      create_more_maps: "Créer d'autres cartes",
      checkout: "Caisse",
      subtotal: "Sous-total",
      free_shipping: "Livraison - GRATUITE",
      item_total: "Total des articles",
      remove: "Retirer",
      quantity: "Quantité",
      title: "Titre",
      subtitle: "Sous-titre",
      size: "Taille",
      style: "Style",
      creation_id: "Code unique",
      map_preview_loading: "L'aperçu de votre carte est généré..."
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.ts = this.ts_source[this.lang];
  }

  @Input() checkout: any;
  @Input() displays: any;
  @Input() lang: string;
  @Output() CartEvent = new EventEmitter();
  @Output() DisplayEvent = new EventEmitter();


  setDisplays = (display_obj): void => {
    this.DisplayEvent.emit(display_obj);
  }

  changeLineItemQuantity = ($event, LineItemObj): void => {

    this.CartEvent.emit(
      {
        action: 'changeLineItemQuantity',
        data: {
          quantity: parseInt($event.srcElement.value),
          lineItem: LineItemObj
        }
      }
    )

    //  console.log($event);
  }
  removeLineItem = (LineItemObj): void => {
    console.log(LineItemObj);
    this.CartEvent.emit(
      {
        action: 'removeLineItem',
        data: LineItemObj
      }
    )
  }

}
