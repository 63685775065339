import { APP_INITIALIZER, Component, OnInit, ViewChild } from '@angular/core';
import * as ShopifyBuy from 'shopify-buy';
import { GetterService } from "./getter.service";
import Canvg from 'canvg';
import { CookieService } from 'ngx-cookie-service';
import * as WebFontLoader from 'webfontloader'
import { Loader } from "@googlemaps/js-api-loader";
import { UiComponent } from './ui/ui.component';
import { ObjectUnsubscribedError, Subject } from 'rxjs';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { async } from '@angular/core/testing';
import { isDeepStrictEqual } from 'util';
import ClientU from 'shopify-buy/index.unoptimized.umd';
import { ppid } from 'process';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

  providers: [GetterService]
})
export class AppComponent implements OnInit {
  displays: {
    map_editor: boolean,
    gallery: boolean,
    email_input: boolean,
    cart: boolean,
    processing: boolean,
    splash: boolean,
    email_input_post: boolean,
    back_to_gallery_bar: boolean,
    processing_message: string,
    top_bar: boolean
  };
  tmp_img_src = [];
  constructor(private getter: GetterService, private cookieService: CookieService) { }

  faBars = faBars;
  background_image_element: HTMLElement;
  email_hash: string;
  destination: string;

  client: any;
  checkout_id: string;
  checkout: any;
  pricelist: any;
  lang = "en";
  cart_name = "ilikemaps";
  cart_currency = "USD";
  cart_country = "US";

  loader = new Loader({
    apiKey: "AIzaSyAQZlj5vu2IpzRl0X5qzUbh0yvWOH8kbRc",
    version: "weekly",
    libraries: ["places"]
  }); // GMAP LOADER

  gMapLoader: Subject<any> = new Subject();
  newCityQuery: Subject<any> = new Subject();
  previewEmailEntered: Subject<any> = new Subject();
  displaysSubject: Subject<any> = new Subject();

  ts_source = {
    en: {
      please_wait_updating_cart: "Please wait while we're updating your cart",
    },
    fr: {
      please_wait_updating_cart: "Veuillez patienter, nous mettons à jour votre panier.",

    }
  }


  gMapLoaded() {
    this.gMapLoader.next(true);
  }


  ngOnInit() {


    /*
        const clientU = ClientU.buildClient({
          domain: 'ilikemaps.com',
          storefrontAccessToken: '5ea6303a7506b08cbed22e6a311b650f'
        });
    */
    /*
        const productsQuery = clientU.graphQLClient.query('', {}, (root) => {
          root.addConnection('products', { args: { first: 5 } }, (product) => {
            product.add('title');
            product.add('id');// Add fields to be returned
          });
        });
    */
    // console.log(productsQuery.toString());

    // Call the send method with the custom products query
    //   clientU.graphQLClient.send(productsQuery).then(({ model, data }) => {
    // Do something with the products
    //    console.log(data);
    //  });

    this.displays = {
      map_editor: false,
      gallery: false,
      email_input: false,
      cart: false,
      processing: false,
      splash: false,
      email_input_post: false,
      back_to_gallery_bar: false,
      processing_message: "",
      top_bar: false
    };
    this.background_image_element = document.querySelector('#background_image');

    const urlParams = new URLSearchParams(window.location.search);
    this.email_hash = urlParams.get('email_hash');
    this.destination = urlParams.get('d');
    if (urlParams.get('lang')) {
      this.lang = urlParams.get('lang');
    } else {
      this.lang = 'en';
    }

    const currency_list = {
      'USD': { currency: 'USD', country: 'US' },
      'CAD': { currency: 'CAD', country: 'CA' }
    };

    let cur = 'USD';
    if (urlParams.get('cart_currency') === 'CAD' || urlParams.get('cart_currency') === 'USD') {
      cur = urlParams.get('cart_currency');

    } else {
      cur = 'USD';
    }

    this.cart_currency = currency_list[cur].currency;
    this.cart_country = currency_list[cur].country;



    WebFontLoader.load({
      active: async () => {
        this.initializeShopifyClient().then(() => {

          // this.update_currency();


        });
        if (this.destination === "gallery" && this.email_hash) {
          this.displays = {
            map_editor: false,
            gallery: true,
            email_input: false,
            cart: false,
            processing: true,
            splash: false,
            email_input_post: false,
            back_to_gallery_bar: true,
            processing_message: "",
            top_bar: true
          };

          this.displaysSubject.next(this.displays);
        }
      },
      typekit: {
        id: 'rap7sli'
      }
    });

    this.loader.load().then(() => {
      this.gMapLoader.next("text");
    });
    this.displaysSubject.next(this.displays);
    //this.checkout = { lineItems: [] }

  }


  initializeShopifyClient = async () => {


    this.client = ShopifyBuy.buildClient({
      /*domain: 'ilikemaps.myshopify.com',*/
      domain: 'ilikemaps.com',
      storefrontAccessToken: '5ea6303a7506b08cbed22e6a311b650f'
    });





    //console.log(this.client);

    const cookie_checkout_id = this.cookieService.get('checkout_id');


    //console.log(cookie_checkout_id);


    await this.client.checkout.fetch(cookie_checkout_id).then(async (checkout) => {
      this.displays.map_editor = true;
      this.displays.splash = false;
      this.checkout_id = checkout.id;
      this.checkout = await this.refreshLineItems(checkout);

    }).catch(async (e) => {
      
            const create_param = {
              presentmentCurrencyCode: this.cart_currency
            };
      
    /*  const create_param = {
        CurrencyCode: this.cart_currency
      }*/

      await this.client.checkout.create(create_param).then(async (checkout) => {
        this.receiveToggleDisplay({ change: "only", splash: true });
        this.checkout_id = checkout.id;
        this.checkout = await this.refreshLineItems(checkout);
      }).catch(
        async (e) => {
          console.log(e);
        }
      );
    });

    /*
      console.log('cookie doesnt exists');
      await this.client.checkout.create().then((checkout) => {
        this.checkout_id = checkout.id;
        this.checkout = checkout;
      });
    */

    var today = new Date();
    var expires = new Date();
    expires.setDate(today.getDate() + 5);

    this.cookieService.set('checkout_id', this.checkout_id, { expires: expires });
    console.log('client_id_loaded');



  }



  check_prices_by_currency = async (sizes) => {
    console.log(sizes);
    const ids = [];
    for (var s of sizes) {
      // console.log(s);
      ids.push(s.variant_id);
    }
    console.log(ids);
    console.log(this.client);
    /*  this.client.product.fetchMultiple(ids).then((products) => {
        // Do something with the products
        console.log(products);
      });*/

  }


  cartEvent = async (event) => {
    var checkout;

    if (event.action === "addToCart") {
      checkout = await this.addToCart(event.data);
    }

    if (event.action === "removeLineItem") {
      checkout = await this.removeLineItem(event.data);
    }

    if (event.action === "changeLineItemQuantity") {
      checkout = await this.changeLineItemQuantity(event.data);
    }

    if (event.action === "check_prices_by_currency") {
      // this.check_prices_by_currency(event.data);
    }

    // console.log(checkout);
    //this.checkout = checkout;


  }

  changeLineItemQuantity = async (data) => {
    this.displays.processing = true;
    this.displays.processing_message = this.ts_source[this.lang].please_wait_updating_cart;
    var return_c;
    const custom = data.lineItem.custom;
    const lineItemId = data.lineItem.id;
    const lineItemsToUpdate = [{
      quantity: data.quantity,
      id: lineItemId
    }];
    await this.client.checkout.updateLineItems(this.checkout_id, lineItemsToUpdate).then(async (c) => {
      c = await this.refreshLineItems(c);
      this.checkout = c;
      return_c = c;
    });
    this.displays.processing = false;
    this.displays.cart = true;
    return return_c;
  }

  removeLineItem = async (lineItemObj) => {
    this.displays.processing = true;
    this.displays.processing_message = "Please wait while we're updating your cart";
    var return_c;
    await this.client.checkout.removeLineItems(this.checkout_id, [lineItemObj.id]).then(async (c) => {
      c = await this.refreshLineItems(c);
      this.checkout = c;
      return_c = c;
    });
    this.displays.processing = false;
    this.displays.cart = true;
    return return_c;
  }

  addToCart = async (creation) => {
    const lineItemsToAdd = [
      {
        variantId: creation.variant_id,
        quantity: 1,
        customAttributes: [
          { key: "Creation ID", value: creation.id },
          { key: "Title", value: creation.title },
          { key: "Subtitle", value: creation.subtitle },
          { key: "Size", value: creation.size.name },
          { key: "Style", value: creation.style.name }
        ]
      }
    ];
    var return_c;
    await this.client.checkout.addLineItems(this.checkout_id, lineItemsToAdd).then(async (c) => {
      c = await this.refreshLineItems(c);
      this.checkout = c;
      return_c = c;
    });
    this.displays.processing = false;
    this.displays.cart = true;
    return return_c;
  }

  refreshLineItems = async (checkout) => {
    //console.log(this.tmp_img_src);
    var item_count = 0;
    checkout.lineItems.forEach(lineItem => {
      item_count += lineItem['quantity'];
      lineItem['custom'] = [];

      lineItem['presentmentAmount'] = lineItem.variant.priceV2.amount;
      lineItem['presentmentCurrency'] = lineItem.variant.priceV2.currencyCode;

  /*    for (const pp of lineItem.variant.presentmentPrices) {
        if (pp.price.currencyCode === this.cart_currency) {
          lineItem['presentmentAmount'] = Number(pp.price.amount);
          lineItem['presentmentCurrency'] = pp.price.currencyCode;
          break
        }
      }*/
      //lineItems['presentmentAmount']


      lineItem.customAttributes.forEach(customAttribute => {
        lineItem['custom'][customAttribute.key] = customAttribute.value;
      });
      if (this.tmp_img_src[lineItem['custom']['Creation ID']]) {
        lineItem['custom']['jpeg_src'] = this.tmp_img_src[lineItem['custom']['Creation ID']];
      }
      else {
        this.getter.getCreationById(lineItem['custom']['Creation ID']).subscribe(
          async (data) => {

            var currentCreation = data[0];
            // console.log(currentCreation);
            if (currentCreation.jpeg_saved) {
              lineItem['custom']['jpeg_src'] = currentCreation.jpeg_url;
              this.tmp_img_src[lineItem['custom']['Creation ID']] = currentCreation.jpeg_url;
            } else {
              const canvas = document.createElement("canvas");
              await this.canvg_svg_to_jpeg(currentCreation.svg_src, canvas)
                .then(async r => {
                  await this.getter.saveJpeg(lineItem['custom']['Creation ID'], r).subscribe(async (data) => {
                    this.setJpegSrc(this.checkout, data.id, data.jpegUrl);
                    //    this.tmp_img_src.push({ id: data.id, src: data.jpegUrl });
                    this.tmp_img_src[data.id] = data.jpegUrl;
                  });

                });
            }
          });
      }
    });
    checkout.itemCount = item_count;


    // append locale param to checkout

    let url = new URL(checkout.webUrl);
    url.searchParams.append('locale', this.lang)
    checkout.localized_url = url.href;
    return checkout;
  }

  setJpegSrc = (checkout, id, jpegSrc) => {
    checkout.lineItems.forEach(lineItem => {
      if (lineItem['custom']['Creation ID'] === id) {
        lineItem['custom']['jpeg_src'] = jpegSrc;
      }

    })
  }

  canvg_svg_to_jpeg = async (svg_str: string, canvas: HTMLCanvasElement): Promise<string> => {
    const ctx = canvas.getContext('2d');
    var v = await Canvg.from(ctx, svg_str);
    await v.render();
    var img_src = canvas.toDataURL("image/jpeg");
    v.stop();
    return img_src;
  }


  receiveToggleDisplay = (new_displays) => {


    if (new_displays.change === "only") {
      for (const [bkey, bvalue] of Object.entries(this.displays)) {
        this.displays[bkey] = false;
      }
    }
    if (new_displays.change === "relative" || new_displays.change === "only") {
      for (const [key, value] of Object.entries(new_displays)) {
        for (const [bkey, bvalue] of Object.entries(this.displays)) {
          if (key === bkey) {
            this.displays[key] = value;
          }
        }
      }
    } else {
      this.displays = new_displays;
    }
    this.displaysSubject.next(this.displays);
    return this.displays;
  }

  receiveCityQuery = ($event) => {
    // console.log($event);
    this.newCityQuery.next($event);
  }


  receiveEmailEntered = ($event) => {
    this.previewEmailEntered.next($event);
  }

  adjust_bg = (): void => {

    var background_size = Math.floor(this.background_image_element.clientHeight * 0.88) + 148;
    var background_position_y = this.background_image_element.clientHeight - background_size;

    this.background_image_element.style.backgroundSize = background_size + "px";
    // this.background_image_element.style.backgroundPositionY = background_position_y + "px";
  }
}