import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { UiComponent } from './ui/ui.component';
import { environment } from '../environments/environment';
import { GalleryComponent } from './gallery/gallery.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { CartComponent } from './cart/cart.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookieService } from 'ngx-cookie-service';
import { SplashComponent } from './splash/splash.component';
import { EmailInputPostComponent } from './email-input-post/email-input-post.component';
import { TopBarComponent } from './top-bar/top-bar.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  declarations: [
    AppComponent,
    UiComponent,
    GalleryComponent,
    EmailInputComponent,
    CartComponent,
    SplashComponent,
    EmailInputPostComponent,
    TopBarComponent
  ],
  providers: [Title, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
