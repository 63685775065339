import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, Inject } from '@angular/core';
import { GetterService } from "../getter.service";
import Canvg from 'canvg';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
  providers: [GetterService]
})
export class GalleryComponent implements OnInit {

  constructor(private getter: GetterService) {
  }

  creations: any;
  sizes: any;
  faPlus = faPlus;
  ts: any;
  ts_source = {
    en: {
      my_map_gallery: "My Map Gallery",
      price: "Price",
      size: "Size",
      style: "Style",
      back_to_creator: "Back to the Map Creator",
      add_map_to_cart: "Add Map to Cart"
    },
    fr: {
      my_map_gallery: "Mes créations cartographiques",
      price: "Prix",
      size: "Taille",
      style: "Style",
      back_to_creator: "Retourer au créateur de carte",
      add_map_to_cart: "Ajouter la carte au panier"
    }
  }

  ngOnInit(): void {
    this.ts = this.ts_source[this.lang];

    this.getter.update_currency(this.cart_country, this.lang).then(
      (sizes) => {
        this.sizes = sizes;
      }
    )

    this.load_creations_from_email_hash(this.email_hash);
    /*  this.ChangeBgEvent.emit()*/
  }

  @Input() email_hash: string;
  @Input() lang: string;
  @Input() cart_currency: string;
  @Input() cart_country: string;
  @Output() DisplayEvent = new EventEmitter();
  @Output() CartEvent = new EventEmitter();

  setDisplays = (display_obj): void => {
    this.DisplayEvent.emit(display_obj);

  }

  add_to_cart = (creation) => {
    //console.log(creation);
    this.CartEvent.emit(
      {
        'action': 'addToCart',
        'data': creation
      });
  }

  load_creations_from_email_hash = async (email_hash: string): Promise<any> => {

    this.getter.getCreationsByEmailHash(email_hash).subscribe(
      async data => {
        this.setDisplays({ change: 'relative', map_editor: false, gallery: true, processing: false });
        this.creations = data;
        for (let i = 0; i < this.creations.length; i++) {
          const cur_c = this.creations[i];
          this.creations[i].price = this.creations[i].size.cost;
          this.creations[i].currency = "USD";
          let tmp_size;
          if (tmp_size = this.sizes.find(size => {
            return size.variant_id === this.creations[i].size.variant_id;
          })) {
            this.creations[i].price = Number(tmp_size.cur_pl.priceV2.amount);
            this.creations[i].currency = tmp_size.cur_pl.priceV2.currencyCode;
          }



          if (this.creations[i].size.names) {
            this.creations[i].size.name = this.creations[i].size.names[this.lang];
          }

          this.creations[i].variant_id = this.creations[i].size.variant_id;

          if (cur_c.jpeg_saved) {
            this.creations[i].jpeg_src = cur_c.jpeg_url;
          } else {
            const canvas = document.createElement("canvas");
            await this.canvg_svg_to_jpeg(cur_c.svg_src, canvas).then(async r => {
              await this.getter.saveJpeg(cur_c.id, r, true).subscribe(async (data) => {
                this.setJpegSrcGallery(data.id, data.jpegUrl);
              });
            });
          }
        }
      }
    );

  }
  setJpegSrcGallery = async (id, jpeg_url) => {
    this.creations.forEach(c => {
      if (c.id === id) {
        c.jpeg_src = jpeg_url;
      }
    })
  }

  canvg_svg_to_jpeg = async (svg_str: string, canvas: HTMLCanvasElement): Promise<string> => {

    const ctx = canvas.getContext('2d');
    var v = await Canvg.from(ctx, svg_str);
    await v.render();
    var img_src = canvas.toDataURL("image/jpeg");
    v.stop();
    return img_src;
  }
}




