import { Component, HostListener, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, AfterViewChecked } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader";
import { GetterService } from "../getter.service";
import { Title } from '@angular/platform-browser';
import Canvg from 'canvg';
import { faLock, faPlus, faSave, faBackward } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';


//import { } from '@types/googlemaps';

//declare function initialize(geocoder:any): any;
declare var google: any;
declare var mapboxgl: any;

mapboxgl.accessToken = 'pk.eyJ1Ijoib2xpdmllcmdyYXR0b25nYWduZSIsImEiOiJjaXVibDV3d2MwMDY4MzRxZnhhdDF5YzhmIn0.PJYEkVwIVL5JzmMRu-AN2A';

@Component({
  selector: 'app-ui',
  templateUrl: './ui.component.html',
  styleUrls: ['./ui.component.css'],
  providers: [GetterService]

})
export class UiComponent implements OnInit {

  constructor(private getter: GetterService, private titleService: Title) {
  }

  @Input() lang: string;
  @Input() cart_currency: string;
  @Input() cart_country: string;
  @Input() checkout: any;
  @Input() gMapLoader: any;
  @Input() newCityQuery: any;
  @Input() previewEmailEntered: any;
  @Input() getDisplays: any;
  @Output() DisplayEvent = new EventEmitter();
  //@Output() ChangeBgEvent = new EventEmitter();
  @Output() CartEvent = new EventEmitter();


  //@ViewChild('preview_title') preview_title: ElementRef;


  faPlus = faPlus;
  faLock = faLock;
  faSave = faSave;
  faBackWard = faBackward;
  display_email_input = false;

  title_input: string;
  city_query: string = "Montreal";
  subtitle_input: string;

  sizes: any;
  styles: any;
  geoCoder: any;

  loader = new Loader({
    apiKey: "AIzaSyAQZlj5vu2IpzRl0X5qzUbh0yvWOH8kbRc",
    version: "weekly",
    libraries: ["places"]
  });

  dollar_amount = 25;


  title_input_element: HTMLInputElement;
  subtitle_input_element: HTMLInputElement;

  style_selector_element: HTMLInputElement;
  size_selector_element: HTMLInputElement;

  title_element: HTMLElement;
  subtitle_element: HTMLElement;
  double_border_box_element: HTMLElement
  test_title_w: HTMLElement;

  //style_recap: HTMLElement;
  coor_s_element: HTMLInputElement;
  coor_w_element: HTMLInputElement;
  coor_n_element: HTMLInputElement;
  coor_e_element: HTMLInputElement;
  coor_z_element: HTMLInputElement;

  city_query_element: HTMLInputElement;


  design_column_element: HTMLElement;
  edit_column_element: HTMLElement;
  wrapper_element: HTMLElement;

  item_added_element: HTMLElement;
  wait_for_item_to_add_element: HTMLElement;
  //size_recap_element: HTMLElement;
  title_recap_element: HTMLElement;

  zoom_control_element: HTMLElement;


  map_paper_element: HTMLElement;


  map_element: HTMLElement;


  current_style: any = { title: {}, subtitle: {}, title_box: {}, double_border_box: {}, subtitle_hide: false };

  selected_size_id = "100_8x10";
  selected_style_id = 82;
  zoom_control_value = 0;

  titles = {
    map_title: 'xyz',
    map_subtitle: 'abc'
  }

  notprocessing: boolean;

  map: any;
  ts: any;

  autocomplete: any;
  recap_column_show = false;
  style_column_show = false;
  location_query_show = true;
  map_paper_zIndex = -1000;

  result_geo: any;

  preview_image_src = "";
  show_back_to_gallery_bar = false;

  cart = 'ilm_shopify';

  //gMapLoader: Subject<any>;



  async ngOnInit() {

    // main structure
    this.design_column_element = document.querySelector('#design_column');
    this.edit_column_element = document.querySelector('#edit_column');
    this.wrapper_element = document.querySelector('#wrapper');

    // map preview in the design area

    this.test_title_w = document.querySelector('#test_title_w');
    this.map_paper_element = document.querySelector('#map_paper');
    this.map_element = document.querySelector("#map");
    this.title_element = document.querySelector('#title');
    //console.log(this.title_element);
    this.subtitle_element = document.querySelector("#subtitle");
    this.zoom_control_element = document.querySelector("#zoom_control");
    this.double_border_box_element = document.querySelector('#double_border_box');

    // input edit column
    this.city_query_element = document.querySelector('#city_query');
    this.style_selector_element = document.querySelector('#style_selector');
    this.size_selector_element = document.querySelector('#size_select');
    this.title_input_element = document.querySelector('#map_title_input');
    this.subtitle_input_element = document.querySelector("#map_subtitle_input");

    //recap & info
    //this.style_recap = document.querySelector('#style_recap');
    this.coor_s_element = document.querySelector('#coor_s');
    this.coor_w_element = document.querySelector('#coor_w');
    this.coor_n_element = document.querySelector('#coor_n');
    this.coor_e_element = document.querySelector('#coor_e');
    this.coor_z_element = document.querySelector('#zoom');
    this.item_added_element = document.querySelector('#item-added');
    this.wait_for_item_to_add_element = document.querySelector('#wait-for-item-to-add');
    // this.size_recap_element = document.querySelector('#size_recap');
    this.title_recap_element = document.querySelector('#title_recap');

    this.ts = this.ts_source[this.lang];
    this.titleService.setTitle(this.ts.page_title);

    this.notprocessing = true;

    this.gMapLoader.subscribe(event => {
      this.load_gmap_features();
    });

    this.newCityQuery.subscribe(event => {
      this.city_query_element.value = event;
      this.city_query_func();
    })

    this.previewEmailEntered.subscribe(event => {
      this.preview_email_entered(event);
    });

    this.getDisplays.subscribe(event => {

      this.show_back_to_gallery_bar = event.back_to_gallery_bar;
      setTimeout(() => {
        this.style_change();
        this.change_paper_size();
        this.map_paper_zIndex = 5;
      }, 500);
    });

// re-run resize to fix some loading bugs
    setTimeout(() => {
      this.change_paper_size();
    }, 4000);



  }


  setDisplays = (display_obj): void => {
    this.DisplayEvent.emit(display_obj);
    if (display_obj.email_input) {
      this.display_email_input = true;
    } else {
      this.display_email_input = false;
    }
  }

  canvg_svg_to_jpeg = async (svg_str: string, canvas: HTMLCanvasElement): Promise<string> => {

    const ctx = canvas.getContext('2d');
    var v = await Canvg.from(ctx, svg_str);
    //console.log(v);
    await v.render();
    var img_src = canvas.toDataURL("image/jpeg");
    //   this.preview_image_src = img;
    v.stop();
    //   document.write('<img src="' + img + '"/>');
    return img_src;
  }

  get_sizes_and_styles = async () => {

    this.getter.update_currency(this.cart_country, this.lang).then(
      (sizes) => {
        this.sizes = sizes;
      }
    )

    /* this.getter.getSizes().subscribe(
       data => {
         this.sizes = data;
         // this.sizes = this.update_currency(this.sizes);
         for (var s of this.sizes) {
 
           s.variant_id = s.cart[this.cart_name].variant_id;
           s.name = s.cart[this.cart_name].name;
           s.cost = s.cart[this.cart_name].cost;
         }
 
       });*/
    //console.log(this.cart_name);


    /*
            this.CartEvent.emit(
              {
                'action': 'check_prices_by_currency',
                'data': this.sizes
              });
    */


    // console.log(this.sizes);
    this.getter.getStyles().subscribe(
      data => {
        this.styles = data;
      }
    );
    // console.log(result_geo);
  }


  load_gmap_features() {
    this.get_sizes_and_styles();

    //  console.log("load_gmap_features");
    this.geoCoder = new google.maps.Geocoder();
    const options = {
      fields: ["address_components", "icon", "name"],
      types: ["address", "locality"],
      strictBounds: false
    };
    this.autocomplete = new google.maps.places.Autocomplete(this.city_query_element);

    this.autocomplete.addListener("place_changed", () => {
      this.city_query_func();
    });
    this.city_query_func();
  }



  city_query_func = async () => {
    this.find_address()
      .then((result_geo) => {
        // console.log(result_geo);
        this.update_titles_from_geo();
        this.style_change();
        this.change_paper_size();
        this.map_paper_zIndex = 5;
      })
      .catch(e => console.log(e));


  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.change_paper_size();
  }



  prep_post_object = (user_email: string = null): any => {
    const coor = this.get_coor(this.map);
    return {
      s: coor.s,
      w: coor.w,
      n: coor.n,
      e: coor.e,
      cart_currency: this.cart_currency,
      cart_name: 'ilikemaps',
      zoom: this.map.getZoom() - 1,
      map_w: this.map_element.clientWidth,
      map_h: this.map_element.clientHeight,
      style_id: this.selected_style_id,
      size_id: this.selected_size_id,
      title: this.titles.map_title,
      subtitle: this.titles.map_subtitle,
      user_email: user_email,
      lang: this.lang,
      art_dims: this.calc_art_dims(this.titles.map_title, this.titles.map_subtitle, 75, 1, this.load_size(), this.load_style())
    };
  }

  preview_email_entered = (user_email): void => {
    const post_object = this.prep_post_object(user_email);
    this.setDisplays({ change: 'relative', processing: true, email_input: false });
    this.getter.generatePreview(post_object).subscribe(
      data => {
        console.log(data);
        this.setDisplays({ change: 'relative', processing: false, email_input_post: true });
      }
    );
  }

  select_style = (index: number): void => {
    this.selected_style_id = index;
    this.style_change();
    this.change_paper_size();
  }

  update_titles_from_geo = (): void => {

    if (this.result_geo.title) {
      this.titles.map_title = this.result_geo.title.toUpperCase();
    } else {
      this.titles.map_title = "";
    }

    if (this.result_geo.subtitle) {
      this.titles.map_subtitle = this.result_geo.subtitle.toUpperCase();
    } else {
      this.titles.map_subtitle = "";
    }

    setTimeout(() => {
      this.update_titles();
    }, 1000)

  }

  update_titles_from_input = (): void => {
    this.titles.map_title = this.title_input_element.value;
    this.titles.map_subtitle = this.subtitle_input_element.value;

    this.update_titles();
  }

  update_titles = (): void => {

    this.style_change();
    this.change_paper_size();

  }


  find_locality_and_country = (results) => {
    const r = results[0];
    const ac = r.address_components;
    var return_obj = { city: null, country: null }

    for (let i = 0; i < ac.length; i++) {
      const act = ac[i].types;
      for (let j = 0; j < act.length; j++) {
        if (act[j] === "locality") {
          return_obj.city = ac[i].long_name;
        }
        else if (act[j] === "country") {
          return_obj.country = ac[i].long_name;
        }
      }
    }
    return return_obj;
  }

  find_address = () => {
    return new Promise((resolve, reject) => {
      this.geoCoder.geocode({ 'address': this.city_query_element.value }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          var cc = this.find_locality_and_country(results);

          if (!cc.city && cc.country) {
            cc = {
              city: cc.country,
              country: cc.city
            }
          }
          var result_geo = {
            center_lat: results[0].geometry.location.lat(),
            center_lon: results[0].geometry.location.lng(),
            title: cc.city,
            subtitle: cc.country,
            full_result: results[0]
          }
          this.result_geo = result_geo;
          this.map = new mapboxgl.Map({
            container: 'map',
            center: [result_geo.center_lon, result_geo.center_lat],
            zoom: 12,
            style: "mapbox://styles/oliviergrattongagne/ckiyznhiu0v7419k3i1py1xzj",
            attributionControl: false
          });
          resolve(result_geo);
        } else {
          console.log(this.city_query_element.value);
          reject('Geocode was not successful for the following reason (find_address): ' + status)
        }
      });
    });
  }


  small_zoom = function (change) {

    // this.zoom_control_value;
    //var zoom_value: number = this.map.getZoom();
    var new_zoom_value = this.zoom_control_value + change;
    if (new_zoom_value < 0) {
      new_zoom_value = 0.45;
      this.map.zoomOut({ animate: false });
    } else if (new_zoom_value >= 0.5) {
      //new_zoom_value = 12;
      new_zoom_value = 0;
      this.map.zoomIn({ animate: false });
    }
    this.zoom_control_value = Math.round(new_zoom_value * 100) / 100;
    this.style_change();
    this.change_paper_size();
  }
  big_zoom = function (change) {
    if (change > 0) this.map.zoomIn({ animate: false });
    else if (change < 0) this.map.zoomOut({ animate: false })
    this.style_change();
    this.change_paper_size();
  }

  style_selected = function () {
    this.change_paper_size();
    this.style_change();
    this.change_paper_size();
  }

  load_size = () => {
    const paper_size = this.sizes.find(size => {
      return size.size_id === this.selected_size_id;
    })
    return paper_size;
  }

  load_style = () => {
    const style = this.styles.find(style => {
      return style.style_id === this.selected_style_id;
    })
    return style;
  }

  style_change = function () {

    const style_selected = this.load_style();

    var style_url = 'mapbox://styles/oliviergrattongagne/' + style_selected.style_mb_id;

    var last_map = 0;
    /* for (var property in this.map._layers) {
       if (Number(property) > last_map) {
         last_map = Number(property);
       }
     }
     this.map._layers[last_map].remove();
  */
    //  map.mapbox.styleLayer(style_url).addTo(map);
    this.map.setStyle(style_url);

    //this.style_recap.innerHTML = style_selected.name;

    this.map.attribution = '';

    // todo rewrite 

    this.current_style.title.fontFamily = style_selected.font_family;
    this.current_style.title.fontWeight = style_selected.font_weight;
    // this.current_style.title.fontColor = style_selected.font_color;

    /*
        this.title_element.style.fontFamily = style_selected.font_family;
        this.title_element.style.fontWeight = style_selected.font_weight;
    */
    if (style_selected.font_color) {
      this.current_style.title.color = style_selected.font_color;
      this.current_style.subtitle.color = style_selected.font_color;
    } else {
      this.current_style.title.color = "#000";
      this.current_style.subtitle.color = "#000";
    }

    this.current_style.subtitle.fontFamily = style_selected.font_family;
    this.current_style.subtitle.fontWeight = style_selected.font_weight;
    /*
        this.test_title_w.style.fontFamily = style_selected.font_family;
        this.test_title_w.style.fontWeight = style_selected.font_weight;
    */

    //this.current_style.title.background = style_selected.bg_style;
    this.current_style.title_box.background = style_selected.bg_style;

    // todo rewrite
    var zoom_value = this.zoom_control_value;
    this.map_element.style.webkitTransform = "scale(" + (style_selected.scale_factor + zoom_value) + ")";
  }

  setDim = (dim: number): string => {
    return String(Math.round(dim) + "px");
  }

  change_paper_size = (): void => {


    // DECLARE SIZES
    var paper_size = this.load_size();
    var style = this.load_style();

    //RETRIEVE USER SIZE SELECTION

    var frame_size_px = 10;
    var map_paper_multiple = 0.85;

    this.dollar_amount = paper_size.cost;
    //this.size_recap_element.innerHTML = paper_size.name;

    // RESIZE MAP PAPER & SET RESOLUTION

    this.map_paper_element.style.height = this.setDim(map_paper_multiple * this.wrapper_element.clientHeight);
    this.map_paper_element.style.border = frame_size_px + 'px solid black';


    var actual_h = this.map_paper_element.clientHeight;
    var proportional_w = actual_h * (paper_size.width / paper_size.height);

    if (document.body.clientWidth < this.map_paper_element.clientWidth) {
      var resize_w_value: number = document.body.clientWidth;
    } else {
      var resize_w_value: number = this.design_column_element.clientWidth;
    }

    if (proportional_w > resize_w_value - 40) {
      proportional_w = resize_w_value - 40;
      actual_h = proportional_w * paper_size.height / paper_size.width;
    }

    if (actual_h > 640) {
      actual_h = 640;
      proportional_w = actual_h * paper_size.width / paper_size.height;
    } else if (proportional_w > 640) {
      proportional_w = 640;
      actual_h = proportional_w * paper_size.height / paper_size.width;
    }

    var actual_res = actual_h / paper_size.height;

    var map_paper_width = Math.floor(proportional_w + (2 * frame_size_px));
    var map_paper_height = Math.floor(actual_h + (2 * frame_size_px));

    this.map_paper_element.style.width = this.setDim(map_paper_width);
    this.map_paper_element.style.height = this.setDim(map_paper_height);
    this.map_paper_element.style.marginLeft = this.setDim(Math.floor((this.design_column_element.clientWidth - map_paper_width) / 2));

    //  this.adjust_bg();
    //this.ChangeBgEvent.emit();

    var scale_factor = this.map_element.getBoundingClientRect().width / this.map_element.offsetWidth;

    var art_dims = this.calc_art_dims(this.titles.map_title, this.titles.map_subtitle, actual_res, scale_factor, paper_size, style);

    // SET MAP

    this.map_element.style.width = this.setDim(art_dims.map.width);
    this.map_element.style.height = this.setDim(art_dims.map.height);
    this.map_element.style.marginLeft = this.setDim(art_dims.map.marginLeft);
    this.map_element.style.marginTop = this.setDim(art_dims.map.marginTop);
    this.map_element.style.marginRight = this.setDim(art_dims.map.marginRight);
    this.map_element.style.marginBottom = this.setDim(art_dims.map.marginBottom);

    this.map.resize();


    // SET TITLE 

    this.current_style.title.top = this.setDim(art_dims.title.top);
    this.current_style.title.width = this.setDim(art_dims.title.width);
    this.current_style.title.marginLeft = this.setDim(art_dims.title.marginLeft);
    this.current_style.title.fontSize = this.setDim(art_dims.title.font_size);
    this.current_style.title.letterSpacing = this.setDim(art_dims.letter_spacing);


    // SET SUBTITLE

    this.current_style.subtitle.top = this.setDim(art_dims.subtitle.top);
    this.current_style.subtitle.width = this.setDim(art_dims.subtitle.width);
    this.current_style.subtitle.marginLeft = this.setDim(art_dims.subtitle.marginLeft);
    this.current_style.subtitle.fontSize = this.setDim(art_dims.subtitle.font_size);
    this.current_style.subtitle.letterSpacing = this.setDim(art_dims.letter_spacing);


    // SET TITLE BOX

    this.current_style.title_box.width = this.setDim(art_dims.title_box.width);
    this.current_style.subtitle_hide = art_dims.hide_subtitle;
    this.current_style.title_box.height = this.setDim(art_dims.title_box.height);
    this.current_style.title_box.paddingTop = this.setDim(art_dims.title_box.paddingTop);
    this.current_style.title_box.marginLeft = this.setDim(art_dims.title_box.marginLeft);
    this.current_style.title_box.top = this.setDim(art_dims.title_box.top);
    this.current_style.title_box.border = style.title_box_border_style;


    // SET DOUBLE BORDER BOX WHEN ENABLED

    this.current_style.double_border_box = art_dims.double_border_box;
    this.current_style.double_border_box = {
      display: art_dims.double_border_box.display,
      top: this.setDim(art_dims.double_border_box.top),
      left: this.setDim(art_dims.double_border_box.left),
      width: this.setDim(art_dims.double_border_box.width),
      height: this.setDim(art_dims.double_border_box.height),
      border: style.title_box_double_border_style,
      background: style.title_box_double_border_bg
    };
  }


  calc_art_dims = (title: string, subtitle: string, actual_res: number, scale_factor: number, paper_size: any, style: any): any => {
    var art_dims = {
      paper: <any>{},
      margin: <any>{},
      contentw: 0,
      scale_factor: scale_factor,
      resolution: actual_res,
      map: <any>{},
      title: <any>{},
      subtitle: <any>{},
      title_box: <any>{},
      subtitle_hide: false,
      double_border_box: <any>{},
      letter_spacing: 0
    };

    //SET PAPER SIZES
    art_dims.paper = {
      width_in: paper_size.width,
      width_px: paper_size.width * actual_res,
      height_in: paper_size.height,
      height_px: paper_size.height * actual_res
    }

    // SET MARGIN & CONTENT SIZES
    art_dims.margin.in = paper_size.creator_margin;
    art_dims.margin.px = Math.floor(art_dims.margin.in * actual_res);
    art_dims.contentw = paper_size.width - (art_dims.margin.in * 2);

    // MAP
    //art_dims.scale_factor = this.map_element.getBoundingClientRect().width / this.map_element.offsetWidth;
    art_dims.map.height = Math.floor((paper_size.height - (art_dims.margin.in * 2)) * actual_res / scale_factor)
    art_dims.map.width = Math.floor((art_dims.contentw * actual_res / scale_factor));


    art_dims.map.marginTop = Math.floor(((art_dims.margin.in / scale_factor) - (paper_size.height * ((1 / scale_factor) - 1) / 2)) * actual_res);
    art_dims.map.marginBottom = art_dims.margin.px;
    art_dims.map.marginLeft = Math.floor(((art_dims.margin.in / scale_factor) - (paper_size.width * ((1 / scale_factor) - 1) / 2)) * actual_res);
    art_dims.map.marginRight = art_dims.margin.px;

    art_dims.letter_spacing = style.letter_spacing * art_dims.contentw * actual_res;

    // SET TITLE FONT SIZE

    art_dims.title.font_size = art_dims.contentw * actual_res * style.title_e_size;
    art_dims.title.actual_size = this.test_title_dim(title, art_dims.title.font_size, style.font_family, style.font_weight, art_dims.letter_spacing);

    // SET SUBTITLE FONTSIZE

    art_dims.subtitle.font_size = art_dims.title.font_size * style.basic_subtitle_size;
    art_dims.subtitle.actual_size = this.test_title_dim(subtitle, art_dims.subtitle.font_size, style.font_family, style.font_weight, art_dims.letter_spacing);

    // SET TITLE BOX DIMENSIONS

    art_dims.title_box.width_in = (art_dims.title.actual_size[1] / actual_res) + (art_dims.contentw * style.title_padding);

    // SET TITLE BOX DIMENSIONS -- MAXIMIZE IF style.title_w =1

    art_dims.title_box.width_in = (art_dims.title_box.width_in + ((art_dims.contentw - art_dims.title_box.width_in) * style.title_w));
    if (style.title_fill_bottom) {
      var fill_bottom = 2;
    } else {
      var fill_bottom = 0;
    }
    art_dims.title_box.width = fill_bottom + (art_dims.title_box.width_in * actual_res);
    art_dims.title_box.marginLeft = Math.floor((((art_dims.contentw - art_dims.title_box.width_in) / 2) + art_dims.margin.in) * actual_res);

    // CHECK IF SUBTITLE IS DISPLAYED OR NOT
    if (subtitle.length > 0 && !style.subtitle_hide) {
      var subtitle_multi = 1;
      art_dims.subtitle_hide = false;
    } else {
      var subtitle_multi = 0;
      art_dims.subtitle_hide = true;
    }
    // CALCULATE FINAL TITLE BOX HEIGHT

    art_dims.title_box.height = Math.floor(
      art_dims.title.actual_size[0] + // TITLE HEIGHT
      (art_dims.title.actual_size[0] * style.subtitle_line_height * subtitle_multi) + // LINE HEIGHT FOR SUBTITLE
      (art_dims.contentw * actual_res * style.title_padding) + // BOX PADDING
      (art_dims.subtitle.actual_size[0] * subtitle_multi) +// SUBTITLE HEIGHT
      fill_bottom
    );


    // CALCULATE FINAL TITLE BOX DIMENSIONS
    art_dims.title_box.paddingTop = (art_dims.contentw * actual_res * style.title_padding * 0.5);
    art_dims.title_box.marginLeft = art_dims.title_box.marginLeft - (fill_bottom / 2);
    art_dims.title_box.top = Math.floor((((art_dims.map.height * scale_factor) + art_dims.margin.px) * style.title_y_pos) - art_dims.title_box.height) + fill_bottom;


    // CALC FINAL TITLE DIMENSIONS
    art_dims.title.top = Math.floor(
      (((art_dims.map.height * scale_factor) + art_dims.margin.px) * style.title_y_pos)
      - art_dims.title_box.height)
      + (art_dims.contentw * actual_res * style.title_padding * 0.5)
      + (art_dims.title.actual_size[0] / 2)
      + fill_bottom;
    //    art_dims.title.paddingTop = (art_dims.contentw * actual_res * style.title_padding * 0.5);
    art_dims.title.width = Math.floor(art_dims.contentw * actual_res);
    art_dims.title.marginLeft = Math.floor(art_dims.margin.px);

    // SET SUBTITLE DIMENSIONS

    art_dims.subtitle.top = Math.floor(
      art_dims.title_box.top
      + art_dims.title_box.paddingTop
      + (art_dims.title.actual_size[0] * (1 + style.subtitle_line_height))
      + (art_dims.subtitle.actual_size[0] / 2)
    );
    art_dims.subtitle.width = Math.floor(art_dims.contentw * actual_res);
    art_dims.subtitle.marginLeft = Math.floor(art_dims.margin.px);

    // SET DOUBLE BORDER BOX WHEN ENABLED

    if (style.title_box_double_border_size) {

      var double_box_offset = Math.floor(style.title_box_double_border_size * art_dims.title_box.height);

      art_dims.double_border_box = {
        display: "block",
        top: Math.floor(art_dims.title_box.top - double_box_offset),
        left: Math.floor(art_dims.title_box.marginLeft - double_box_offset),
        width: Math.floor(art_dims.title_box.width + (double_box_offset * 2)),
        height: Math.floor(art_dims.title_box.height + (double_box_offset * 2) /*+ (parseInt('1', 10) * 2)*/),
        border: style.title_box_double_border_style,
        background: style.title_box_double_border_bg
      };
    }
    else {
      art_dims.double_border_box.display = "none";
    }
    return art_dims;
  }


  findIndexByKeyValue = (arraytosearch, key, valuetosearch): number => {

    for (var i = 0; i < arraytosearch.length; i++) {

      if (arraytosearch[i][key] == valuetosearch) {
        return i;
      }
    }
    return null;
  }

  test_title_dim = (title: string, font_size: number, font_family: string, font_weight: string, letter_spacing: number): number[] => {

    if (this.test_title_w) {
      this.test_title_w.innerText = title;
      this.test_title_w.style.fontSize = this.setDim(font_size);
      this.test_title_w.style.fontFamily = font_family;
      this.test_title_w.style.fontWeight = font_weight;
      this.test_title_w.style.letterSpacing = this.setDim(letter_spacing);

      var test_width = (this.test_title_w.clientWidth + 1);
      this.test_title_w.innerText = "E";
      const test_height = (this.test_title_w.clientHeight + 1);
      const test_e_w = (this.test_title_w.clientWidth + 1)
      var dimensions = [test_height, test_width, test_e_w];
    }
    return dimensions;
  }

  getbounds = (): void => {
    const bounds: any = this.map.getBounds();
    this.coor_s_element.value = bounds._southWest.lat;
    this.coor_w_element.value = bounds._southWest.lng;
    this.coor_n_element.value = bounds._northEast.lat;
    this.coor_e_element.value = bounds._northEast.lng;
    this.coor_z_element.value = this.map.getZoom();
  }

  get_coor = (map): any => {
    var bounds = map.getBounds();
    var coor = { s: bounds._sw.lat, w: bounds._sw.lng, n: bounds._ne.lat, e: bounds._ne.lng, z: map.getZoom() };
    return coor;
  }

  add_to_cart_button_clicked = (): any => {
    this.setDisplays({ map_editor: true, processing: true, change: "relative", processing_message: "Please wait while we're finalizing your map and adding it to the cart" });

    const post_object = this.prep_post_object(null);
    this.getter.generatePreviewLowRes(post_object).subscribe(
      async (data) => {
        var creation_to_add = data[0];
        this.CartEvent.emit(
          {
            'action': 'addToCart',
            'data': creation_to_add
          });
      }
    );
  }

  ts_source = {
    'en': {
      'page_title': "Custom Map Prints with iLikeMaps Creator",
      'wait_text': "Your map is being generated and added to cart...",
      'shipping': "Shipping",
      'free_upper': "FREE",
      'cart_success': "Map successfully added to cart!",
      'checkout': "Checkout",
      'find_location': "Find Location",
      'ie': "ie.",
      'find': "Find",
      'set_title': "Title:",
      'set_subtitle': "Subtitle:",
      'set': "Set",
      'choose_style': "Style:",
      'choose_size': "Size:",
      'add_map_to_cart': "Add Map to Cart",
      'return_to_ilm': "Return to iLikeMaps",
      'generate_preview_button': "Save & Generate High Quality Preview",
      'switch_to_gallery': "Saved Map Creations",
    },
    fr: {
      'page_title': "Cartes personnalisées iLikeMaps Creator",
      'wait_text': "Votre carte est en train d&rsquo;être générée et ajoutée au panier...",
      'shipping': "Livraison",
      'free_upper': "GRATUITE",
      'cart_success': "La carte a été ajoutée au panier!",
      'checkout': "Passer la commande",
      'find_location': "Emplacement",
      'ie': "p. ex.",
      'find': "Chercher",
      'set_title': "Titre",
      'set_subtitle': "Sous-Titre",
      'set': "OK",
      'choose_style': "Styles",
      'choose_size': "Tailles",
      'add_map_to_cart': "Ajouter la carte au panier",
      'return_to_ilm': "Retour à ",
      'generate_preview_button': "Aperçu de haute résolution",
      'switch_to_gallery': "Cartes enregistrées"
    }
  }
}
