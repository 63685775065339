<div class="email_input_bg">
    <div class="email_input_wrapper">
        <div class="grid-container email_input_grid_container">
            <div class="grid-x email_input_box" data-closable>
                <button class="close-button" aria-label="Close alert" type="button" data-close
                    (click)="close_email_input()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="cell">
                    <h4>{{ts.we_need_your_email}}</h4>
                    <div [innerHTML]="ts.we_need_email_p"></div>
                    <form name="email_form" id="email_form" #userForm="ngForm" (ngSubmit)="onFormSubmit(userForm)">
                        <label>{{ ts.email_label }}</label>
                        <div class="input-group">

                            <input id="user_email" name="user_email" (ngModel)="user_email" required
                                [pattern]="emailPattern" type="email" class='input-group-field' #user_email="ngModel">

                            <div class="input-group-button">
                                <input type='submit' class='button' [value]="ts.enter_email" id='user_email_button'>
                            </div>

                        </div>
                        <div *ngIf="user_email.errors && userForm.submitted && !isValidFormSubmitted"
                            [ngClass]="'error'">
                            {{ts.email_blank_or_invalid}}
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>