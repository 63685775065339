import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.css']
})
export class EmailInputComponent implements OnInit {

  @Input() lang: string;

  isValidFormSubmitted = false;
  validateEmail = true;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  ts: any;

  ts_source = {
    en: {
      email_label: "Email:",
      enter_email: "Enter Email",
      we_need_your_email: "We need your email address to move forward",
      we_need_email_p: "<p>To save your map, we need to associate it to your email. We'll send you only two (2) email when:</p><ul><li>Your high resolution map preview is completed (takes 5-10 minutes)</li><li>5 days later to remind you of the creation you made</li> ",
      email_blank_or_invalid: "Your email is blank or invalid"
    },
    fr: {
      email_label: "Courriel:",
      enter_email: "Entrez votre courriel",
      we_need_your_email: "Nous avons besoin de votre courriel pour la prochaine étape",
      we_need_email_p: "<p>Pour enregistrer votre carte, nous devons l'associer à votre courriel. Nous vous enverrons deux (2) messages quand:</p><ul><li>Votre aperçu haute résolution sera prêt (environ 5-10 minutes);</li><li>Un rappel 5 jours plus tard de la carte que vous avez créé.</li> ",
      email_blank_or_invalid: "Votre courriel est invalide."
    }

  };



  onFormSubmit(form: NgForm) {
    this.isValidFormSubmitted = false;
    if (form.invalid) {
      return;
    }
    this.isValidFormSubmitted = true;
    this.EmailEntered.emit(form.controls.user_email.value);
    // console.log(form);
    form.resetForm();
  }

  constructor() { }

  ngOnInit(): void {
    this.ts = this.ts_source[this.lang];
  }
  @Input() displays: any;
  @Output() EmailEntered = new EventEmitter();
  @Output() DisplayEvent = new EventEmitter();

  EmailEnteredFunc = (): void => {
    this.EmailEntered.emit(this.user_email);
  }

  close_email_input = (): void => {
    var tmp_displays = this.displays;
    tmp_displays.email_input = false;
    this.setDisplays(tmp_displays);
  }
  setDisplays = (display_obj): void => {
    this.DisplayEvent.emit(display_obj);
  }

  user_email: string;

  email_inputed = () => { }



}
