<div class="email_input_bg">
    <div class="email_input_wrapper">
        <div class="grid-container email_input_grid_container">
            <div class="grid-x email_input_box" data-closable>
                <button class="close-button" aria-label="Close alert" type="button" data-close (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="cell">
                    <h4>{{ts.h4}}</h4>
                    <p>{{ts.p1}}</p>
                    <p>{{ts.p2}}</p>
                    <p>{{ts.p3}}</p>
                    <a class="button expanded rounded" (click)="close()">{{ts.ok}}</a>
                </div>

            </div>
        </div>
    </div>
</div>