<div class="splash_wrapper">
    <div class="grid-x">
        <div class="cell small-10 small-offset-1 medium-6 medium-offset-3 logo_div">
            <img src="../../assets/logo_web.png" class="logo float-center">
        </div>
        <div class="cell h1_div">
            <h1><span class="text_bg">{{ts.h1}}</span></h1>
        </div>

        <div class="cell small-10 small-offset-1 medium-6 medium-offset-3 city_query_div">
            <label>{{ts.city_query_helper}}</label>
            <div class="input-group">
                <input id="splash_city_query" name="splash_city_query" type="text" placeholder="New York City"
                    [(ngModel)]="splash_city_query" class='input-group-field leftinputborder' />
                <div class="input-group-button">
                    <input type='submit' class='button rightinputborder' value="Find" id='splash_city_query_button'
                        (click)="queryCity(splash_city_query_element.value)">
                </div>
            </div>
        </div>
        <div class="cell suggestions">
            <h5>{{ts.suggestions}}</h5>
            <ul class="no-bullet">
                <li><a (click)="queryCity('Musée du Louvre')">Paris, FR</a></li>
                <li><a (click)="queryCity('San Diego USA')">San Diego, US</a></li>
                <li><a (click)="queryCity('Hong Kong HK')">Hong Kong, HK</a></li>
            </ul>
        </div>


    </div>
</div>