import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

declare var google: any;

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})
export class SplashComponent implements OnInit {

  @Input() gMapLoader: any;
  @Input() lang: string;
  @Output() CityQueryEvent = new EventEmitter();
  @Output() DisplayEvent = new EventEmitter();

  ts: any;
  ts_source = {
    en: {
      h1: "Create your map",
      city_query_helper: "Enter any location to start designing your map.",
      suggestions: "Suggestions"
    },
    fr: {
      h1: "Créez votre carte",
      city_query_helper: "Entrez n'importe quel lieu pour commencer",
      suggestions: "Suggestions"
    }
  }

  constructor() { }



  load_gmap_features = (): void => {

    const options = {
      fields: ["address_components", "icon", "name"],
      types: ["address", "locality"],
      strictBounds: false
    };
    this.autocomplete = new google.maps.places.Autocomplete(this.splash_city_query_element);

    this.autocomplete.addListener("place_changed", () => {
      this.queryCity(this.splash_city_query_element.value);
    });

  }

  queryCity = (query: string): void => {
    this.CityQueryEvent.emit(query);
    this.DisplayEvent.emit({ change: "relative", splash: false, map_editor: true, top_bar: true });
  }

  ngOnInit(): void {
    this.ts = this.ts_source[this.lang];
    this.splash_city_query_element = document.querySelector('#splash_city_query');
    this.gMapLoader.subscribe(event => {
      this.load_gmap_features();
    });

  }
  splash_city_query: string;
  autocomplete: any;
  splash_city_query_element: HTMLInputElement;

}
