<div class="grid-x" id="gallery_wrapper">
    <div class="cell medium-10 medium-offset-1">
        <div class="grid-x grid-margin-x grid-margin-y">
            <div class="cell">
                <h1 class="text-center h1_gallery">{{ts.my_map_gallery}}</h1>
            </div>
            <div *ngFor="let creation of creations; index as i" class="cell gallery_cell">
                <div class="grid-x grid-padding-x grid-padding-y">
                    <div class="cell medium-8 img_cell">
                        <img [src]="creation.jpeg_src" class="float-center">
                    </div>
                    <div class="cell medium-4  gallery_desc">
                        <h5>{{creation.title}}</h5>
                        <h6 class="subheader">{{creation.subtitle}}</h6>
                        <ul class='no-bullet'>
                            <li>{{ts.price}}: {{creation.price | currency}}
                                {{creation.currency}}</li>
                            <li>{{ts.size}}: {{creation.size.name}}</li>
                            <li>{{ts.style}}: {{creation.style.name}}</li>
                        </ul>
                        <a class="button" id="add_to_cart_button_1st" (click)="add_to_cart(creation)">
                            <fa-icon [icon]="faPlus">
                            </fa-icon> {{ ts.add_map_to_cart }}
                        </a>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>
<canvas></canvas>