<div class="cart_component">
    <!--   <div class="cart_bg"></div>-->
    <div class="cart_wrapper">
        <div class="grid-container">
            <div class="grid-x cart_box">
                <button class="close-button" aria-label="Close alert" type="button"
                    (click)="setDisplays({change:'relative',cart: false})">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="cell">
                    <div class="grid-x grid-padding-x">
                        <div class="cell">
                            <h2 class="subheader">{{ts.cart}}</h2>
                        </div>
                        <div class="cell large-offset-6 large-3">
                            <a class="button expanded"
                                (click)="setDisplays({change:'relative',map_editor: true, gallery: false,  cart: false})">
                                <fa-icon [icon]="faPlus">
                                </fa-icon> {{ts.create_more_maps}}
                            </a>
                        </div>
                        <div class="cell large-3">
                            <a *ngIf="checkout" class="button expanded success" [href]="checkout.localized_url"
                                target="_blank">
                                <fa-icon [icon]="faLock">
                                </fa-icon> {{ts.checkout}}
                            </a>
                        </div>
                    </div>

                </div>
                <div *ngIf="checkout" class="lineItems cell">
                    <div class="cell lineItem" *ngFor="let lineItem of checkout.lineItems; index as i">
                        <div class="remove_button show-for-large"><a class="button alert small hollow"
                                (click)="removeLineItem(lineItem)">
                                <fa-icon [icon]="faBan"></fa-icon> {{ts.remove}}
                            </a></div>
                        <div class="grid-x grid-padding-x align-middle image_desc">
                            <div class="cell large-5">
                                <div *ngIf="!lineItem.custom.jpeg_src">
                                    <div *ngIf="!lineItem.custom.jpeg_src" class="float-center cart_img">
                                        <img [width]="1000" [height]="1250" class="img_loading">
                                    </div>
                                    <div class="img_loading_text img_loading_text callout warning">
                                        {{ts.map_preview_loading}}</div>

                                </div>
                                <img [src]="lineItem.custom.jpeg_src" class="float-center cart_img">
                            </div>
                            <div class="cell large-7">
                                <!--<h5>{{lineItem.custom.Title}}</h5>-->
                                <div class="grid-x lineItem1stLine">
                                    <div class="cell">

                                        <span class="lineItemTitle">{{lineItem.custom.Title}}</span>
                                    </div>

                                    <div class="cell">


                                        <span class="lineItemPrice">{{lineItem.presentmentAmount | currency}}
                                            {{lineItem.presentmentCurrency}}</span>
                                    </div>
                                    <div class="cell">
                                        <div class="grid-x">
                                            <div class="cell large-3">
                                                <label>{{ts.quantity}}</label>
                                                <input type="number" step="1" min="1" value="{{lineItem.quantity}}"
                                                    (change)="changeLineItemQuantity($event, lineItem)">
                                            </div>
                                        </div>

                                        <div class="grid-x">
                                            <div class="cell hide-for-large">
                                                <a class="button alert small expanded hollow"
                                                    (click)="removeLineItem(lineItem)">
                                                    <fa-icon [icon]="faBan"></fa-icon> {{ts.remove}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-x customAttributesTable">
                                    <div class="cell customAttributeLine">
                                        <div class="grid-x ">
                                            <div class="cell medium-4">
                                                <label>{{ts.title}}</label>
                                            </div>
                                            <div class="cell medium-8">
                                                {{lineItem.custom.Title}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cell customAttributeLine">
                                        <div class="grid-x ">
                                            <div class="cell medium-4">
                                                <label>{{ts.subtitle}}</label>
                                            </div>
                                            <div class="cell medium-8">
                                                {{lineItem.custom.Subtitle}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cell customAttributeLine">
                                        <div class="grid-x ">
                                            <div class="cell medium-4">
                                                <label>{{ts.size}}</label>
                                            </div>
                                            <div class="cell medium-8">
                                                {{lineItem.custom.Size}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cell customAttributeLine">
                                        <div class="grid-x ">
                                            <div class="cell medium-4">
                                                <label>{{ts.style}}</label>
                                            </div>
                                            <div class="cell medium-8">
                                                {{lineItem.custom.Style}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cell customAttributeLine">
                                        <div class="grid-x ">
                                            <div class="cell medium-4">
                                                <label>{{ts.creation_id}}</label>
                                            </div>
                                            <div class="cell medium-8">
                                                <span class="creation_id">{{lineItem.custom['Creation ID']}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cell totalLines" *ngIf="checkout">
                    <div class="grid-x grid-padding-x">
                        <div class="cell large-offset-8 large-4">
                            <div class="grid-x">
                                <div class="cell small-6"><label>{{ts.item_total}}</label></div>
                                <div class="cell small-6 text-right totalLine_subtle">{{checkout.totalPriceV2.amount
                                    |
                                    currency}}
                                    {{checkout.totalPriceV2.currencyCode}}</div>
                            </div>
                            <div class="grid-x">
                                <div class="cell small-6"><label>{{ts.free_shipping}}</label></div>
                                <div class="cell small-6 text-right totalLine_subtle">{{0 | currency}}
                                    {{checkout.totalPriceV2.currencyCode}}
                                </div>
                            </div>
                            <div class="grid-x subtotalLine">
                                <div class="cell small-6"><label>{{ts.subtotal}}</label></div>
                                <div class="cell small-6 text-right">{{checkout.totalPriceV2.amount | currency}}
                                    {{checkout.totalPriceV2.currencyCode}}</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="cell">
                    <div class="grid-x grid-padding-x">
                        <div class="cell large-offset-9 large-3">
                            <a *ngIf="checkout" class="button expanded success" [href]="checkout.localized_url"
                                target="_blank">
                                <fa-icon [icon]="faLock">
                                </fa-icon> {{ts.checkout}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>