<div id="test_title_w">E</div>
<div class="grid-x" id="map_editor_wrapper">
  <div id="edit_column" class="large-3 medium-5 cell small-order-2 medium-order-1">
    <div id="edit_area" class="grid-x grid-padding-x">
      <div class='cell' [hidden]="!location_query_show">
        <form name="city_query_form" id="city_query_form">
          <label>{{ ts.find_location }}</label>
          <div class="input-group">
            <input id="city_query" name="city_query" type="text" placeholder="New York City" [(ngModel)]="city_query"
              spellcheck=false class='input-group-field leftinputborder' />
            <div class="input-group-button">
              <input type='submit' class='button rightinputborder' [value]="ts.find" (click)='city_query_func()'
                id='city_query_button'>
            </div>
          </div>
        </form>
      </div>
      <div class="cell">
        <label>{{ts.set_title}}</label>
        <div class="input-group">
          <input type="text" id="map_title_input" name="map_title" [(ngModel)]="titles.map_title" spellcheck=false
            class='input-group-field  leftinputborder' #title_fix (input)="update_titles_from_input()">
          <div class="input-group-button">
            <input type='submit' class='button rightinputborder' [value]="ts.set" (click)='update_titles_from_input()'
              id='city_query_button'>
          </div>
        </div>
        <label>{{ts.set_subtitle}}</label>
        <div class="input-group">
          <input type="text" id="map_subtitle_input" name="map_subtitle" [(ngModel)]="titles.map_subtitle"
            spellcheck=false class='input-group-field leftinputborder' (input)="update_titles_from_input()"
            #subtitle_fix />
          <div class="input-group-button">
            <input type='submit' class='button rightinputborder' [value]="ts.set" (click)='update_titles_from_input()'
              id='city_query_button'>
          </div>
        </div>
      </div>
      <div class='cell'>
        <label>{{ts.choose_style}}</label>
        <div *ngFor="let style of styles; index as i" class="style_card" (click)="select_style(style.style_id)"
          [ngClass]="{'selected_style' : style.style_id == selected_style_id}" style_id={{style.style_id}}>
          {{style.name}}
        </div>
      </div>
      <div class="cell">
        <label>{{ ts.choose_size }}</label>
        <select name="size_select" id="size_select" [(ngModel)]="selected_size_id" (change)="change_paper_size()">
          <option *ngFor="let size of sizes; index as i" value="{{size.size_id}}">
            {{ size.name }} - {{ size.cost }} $ {{size.currency}}
          </option>
        </select>
      </div>
      <div class="cell">
        <a class="button expanded hollow" id="generate_preview_button"
          (click)="setDisplays({change:'relative',email_input:true, email_reason:'gallery'})">
          <fa-icon [icon]="faSave">
          </fa-icon>
          {{ts.generate_preview_button }}
        </a>
        <a class="button expanded" id="add_to_cart_button_1st" (click)="add_to_cart_button_clicked()">
          <fa-icon [icon]="faPlus">
          </fa-icon> {{ ts.add_map_to_cart }}
        </a>
        <a class="button expanded success" id="checkout_button_1st"
          (click)="setDisplays({change: 'relative', cart:true})">
          <fa-icon [icon]="faLock">
          </fa-icon> {{
          ts.checkout }}<span *ngIf="checkout" class="lineItemCount"> ⁠-
            {{checkout.lineItemsSubtotalPrice.amount | currency}}</span>
        </a>
      </div>
      <div class="cell small-10 small-offset-1 copyright_info" [hidden]="false">
        <div class="grid-x">
          <div class="small-centered cell">
            &copy;OpenStreetMaps Contributors &copy;MapBox &copy;Google
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="design_column" class="medium-7 cell large-9 small-order-1 medium-order-2">
    <div id="map_paper" [style.zIndex]="map_paper_zIndex">
      <div id="map" class="grid-x"></div>
      <div id="map_hide_border" class="grid-x"></div>
      <div id="double_border_box" [ngStyle]="current_style.double_border_box"></div>
      <div id="title_box" [ngStyle]="current_style.title_box"></div>
      <div id="title" [ngStyle]="current_style.title"><span>{{'' + titles.map_title + ''}}</span></div>
      <div id="subtitle" [hidden]="current_style.subtitle_hide" [ngStyle]="current_style.subtitle">
        <span>{{'' + titles.map_subtitle + ''}}</span>
      </div>
    </div>
    <div id="zoom_container" class="show-for-large" [style.zIndex]="map_paper_zIndex">
      <div id="zoom_control">
        <div id="big_zoom_out" (click)="big_zoom(-1)">-</div>
        <div id="small_zoom_out" (click)="small_zoom(-0.05)">-</div>
        <div id="small_zoom_in" (click)="small_zoom(0.05)">+</div>
        <div id="big_zoom_in" (click)="big_zoom(1)">+</div>
        <div id="zoom_label"></div>
      </div>
    </div>
  </div>