import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GetterService {


  query<T>(
    query: string,
    variables?: { [key: string]: any }
  ): Observable<T> {

    const options = {
      headers: new HttpHeaders({
        'X-Shopify-Storefront-Access-Token': '5ea6303a7506b08cbed22e6a311b650f',
        'Content-Type': 'application/json'
      })
    };


    return this.http
      .post<{ data: T }>(`https://ilikemaps.myshopify.com/api/2022-01/graphql.json`, {
        query: query,
        variables: variables
      }, options)
      .pipe(map(d => d.data));
  }

  gql(stringPieces: TemplateStringsArray): string {
    return stringPieces.join("");
  }

  get_currency_graphql = (cart_country: string) => {
    const q = this.gql`
    query @inContext(country: `+ cart_country + `) {
      product(handle: "ilikemapscreatorcustommap") {
        title
        id
        variants(first: 100) {
          edges {
            node {
              title
              id
              priceV2 {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
    `;
    return this.query(q);
  }

  getSizes = () => {
    // return this.getSizesCallable();
    return this.local_sizes;
  }
  getStyles = () => {
    // return this.getStylesCallable();

    return this.local_styles;
  }
  getCreationsByEmailHash = (email_hash: string): any => {
    return this.getCreationsByEmailHashCallable(email_hash);
  }

  testing(): Observable<any> {

    console.log('Request is sent!');
    // Using the POST method
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'timeout': '10000'
      })
    };
    return null
    /* return this.http.post(this.lambda_url,
       {
         'email': 'ankit.codechintan@gmail.com',
         'phone': 910950
       },
       headers)*/

    // return this.http.get(this.lambda_url);

  }

  generatePreview(call_obj) {
    return this.generatePreviewCallable(call_obj);
  }

  generatePreviewLowRes(call_obj) {
    return this.generatePreviewLowResCallable(call_obj);
  }

  getCreationById(id: string) {
    return this.getCreationByIdCallable({ id: id });
  }

  saveJpeg(id, jpegSrc, no_resize = false) {
    return this.saveJpegCallable({
      jpegSrc: jpegSrc,
      id: id,
      no_resize: no_resize
    });
  }

  update_currency = async (cart_country, lang): Promise<any> => {
    return new Promise((resolve) => {
      this.getSizes().subscribe(
        data => {
          const sizes: any = data;

          this.get_currency_graphql(cart_country).subscribe(
            (response: any) => {
              console.log(response);
              const cur_pricelist = response.product.variants.edges;

              for (const pl of sizes) {
                for (const cur_pl of cur_pricelist) {
                  if (cur_pl.node.id === pl.variant_id) {
                    pl.cur_pl = cur_pl.node;
                    break;
                  }
                }
                pl.name = pl.names[lang];
                pl.cost = Number(pl.cur_pl.priceV2.amount);
                pl.currency = pl.cur_pl.priceV2.currencyCode;
              }
              resolve(sizes);
            },
            (error) => {
              console.log(error);
            });
        });
    });
  }




  generatePreviewCallable: any;
  generatePreviewLowResCallable: any;
  getSizesCallable: any;
  getStylesCallable: any;
  testingCallable: any;
  getCreationsByEmailHashCallable: any;
  getCreationByIdCallable: any;
  saveJpegCallable: any;
  constructor(private fns: AngularFireFunctions, private http: HttpClient) {
    this.generatePreviewCallable = fns.httpsCallable('generatePreview');
    this.getSizesCallable = fns.httpsCallable('getSizes');
    this.getStylesCallable = fns.httpsCallable('getStyles');
    this.testingCallable = fns.httpsCallable('testing');
    this.getCreationsByEmailHashCallable = fns.httpsCallable('getCreationsByEmailHash');
    this.generatePreviewLowResCallable = fns.httpsCallable('generatePreviewLowRes');
    this.getCreationByIdCallable = fns.httpsCallable('getCreationById');
    this.saveJpegCallable = fns.httpsCallable('saveJpeg');

  }




  local_sizes = new Observable(sub => {
    sub.next(
      [
        {
          "cart": {
            "ilikemaps":
            {
              //"variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4Njg5OTYyNw==",
              "variant_id": "gid://shopify/ProductVariant/38147786899627",
              "name": "8×10 in.",
              "cost": 25
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDQ3NjkxNQ==",
              "name": "8x10 po.",
              "cost": 31
            }
          },
          names: {
            "en": "8x10 in.",
            "fr": "8x10 po."
          },
          // "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4Njg5OTYyNw==",
          "variant_id": "gid://shopify/ProductVariant/38147786899627",
          "size_id": "100_8x10",
          "width": 8,
          "width_px": 480,
          "height_px": 600,
          "creator_margin": 0.25,
          "order": 10,
          "height": 10
        },
        {
          "cart": {
            "ilikemaps":
            {
              // "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4Njk2NTE2Mw==",
              "variant_id": "gid://shopify/ProductVariant/38147786965163",
              "name": "11×14 in.",
              "cost": 35
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDUwOTY4Mw==",
              "name": "11x14 po.",
              "cost": 44
            }
          },
          names: {
            "en": "11×14 in.",
            "fr": "11x14 po."
          },
          // "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4Njk2NTE2Mw==",
          "variant_id": "gid://shopify/ProductVariant/38147786965163",
          "width_px": 471,
          "order": 10,
          "height": 14,
          "height_px": 600,
          "creator_margin": 0.25,
          "width": 11,
          "size_id": "100_11x14",
        },
        {
          "cart": {
            "ilikemaps":
            {
              //   "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4Njk5NzkzMQ==",
              "variant_id": "gid://shopify/ProductVariant/38147786997931",
              "name": "13×19 in.",
              "cost": 55
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDU0MjQ1MQ==",
              "name": "13×19 po.",
              "cost": 69
            }
          },
          names: {
            "en": "13x19 in.",
            "fr": "13x19 po."
          },
          //  "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4Njk5NzkzMQ==",
          "variant_id": "gid://shopify/ProductVariant/38147786997931",
          "width_px": 410,
          "order": 10,
          "width": 13,
          "creator_margin": 0.5,
          "height": 19,
          "size_id": "100_13x19",
          "height_px": 600
        },
        {
          "cart": {
            "ilikemaps":
            {
              //  "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzAzMDY5OQ==",
              "variant_id": "gid://shopify/ProductVariant/38147787030699",
              "name": "16×20 in.",
              "cost": 80
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDU3NTIxOQ==",
              "name": "16×20 po.",
              "cost": 100
            }
          },
          names: {
            "en": "16x20 in.",
            "fr": "16x20 po."
          },
          // "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzAzMDY5OQ==",
          "variant_id": "gid://shopify/ProductVariant/38147787030699",
          "size_id": "100_16x20",
          "height_px": 600,
          "width_px": 480,
          "order": 10,
          "width": 16,
          "creator_margin": 0.5,
          "height": 20
        },
        {
          "cart": {
            "ilikemaps":
            {
              //    "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzA2MzQ2Nw==",
              "variant_id": "gid://shopify/ProductVariant/38147787063467",
              "name": "18×24 in.",
              "cost": 90
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDYwNzk4Nw==",
              "name": "18x24 po.",
              "cost": 113
            }
          },
          names: {
            "en": "18x24 in.",
            "fr": "18x24 po."
          },
          //     "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzA2MzQ2Nw==",
          "variant_id": "gid://shopify/ProductVariant/38147787063467",
          "order": "10",
          "width": 18,
          "creator_margin": 0.5,
          "size_id": "100_18x24",
          "height_px": 600,
          "width_px": 450,
          "height": 24,
        },
        {
          "cart": {
            "ilikemaps":
            {
              //     "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzA5NjIzNQ==",
              "variant_id": "gid://shopify/ProductVariant/38147787096235",
              "name": "20x28 in.",
              "cost": 115
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDY0MDc1NQ==",
              "name": "20x28 po.",
              "cost": 144
            }
          },
          names: {
            "en": "20x28 in.",
            "fr": "20x28 po."
          },
          //  "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzA5NjIzNQ==",
          "variant_id": "gid://shopify/ProductVariant/38147787096235",
          "height_px": 600,
          "creator_margin": 0.5,
          "height": 28,
          "size_id": "100_20x28",
          "width_px": 428,
          "width": 20,
          "order": 10
        },
        {
          "cart": {
            "ilikemaps":
            {
              //   "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzEyOTAwMw==",
              "variant_id": "gid://shopify/ProductVariant/38147787129003",
              "name": "24x36 in.",
              "cost": 135
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDY3MzUyMw==",
              "name": "24x36 po.",
              "cost": 169
            }
          },
          names: {
            "en": "24x36 in.",
            "fr": "24x36 po."
          },
          //  "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzEyOTAwMw==",
          "variant_id": "gid://shopify/ProductVariant/38147787129003",
          "size_id": "100_24x36",
          "order": 10,
          "height": 36,
          "name": "24×36 in.",
          "creator_margin": 0.75,
          "width": 24,
          "height_px": 600,
          "width_px": 400
        },
        {
          "cart": {
            "ilikemaps":
            {
              // "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzE2MTc3MQ==",
              "variant_id": "gid://shopify/ProductVariant/38147787161771",
              "name": "Framed 8×10 in.",
              "cost": 65
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDcwNjI5MQ==",
              "name": "8×10 po. encadré",
              "cost": 81
            }
          },
          names: {
            "en": "Framed 8x10 in.",
            "fr": "8x10 po. encadré"
          },
          //    "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzE2MTc3MQ==",
          "variant_id": "gid://shopify/ProductVariant/38147787161771",
          "order": 20,
          "creator_margin": 0.25,
          "height": 10,
          "width": 8,
          "size_id": "100_f8x10",
          "width_px": 480,
          "height_px": 600
        },
        {
          "cart": {
            "ilikemaps":
            {
              //"variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzE5NDUzOQ==",
              "variant_id": "gid://shopify/ProductVariant/38147787194539",
              "name": "Framed 18×24 in.",
              "cost": 180
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDczOTA1OQ==",
              "name": "18×24 encadré",
              "cost": 225
            }
          },
          names: {
            "en": "Framed 18x24 in.",
            "fr": "18x24 po. encadré"
          },
          //  "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzE5NDUzOQ==",
          "variant_id": "gid://shopify/ProductVariant/38147787194539",
          "height": 24,
          "width_px": 450,
          "width": 18,
          "order": 20,
          "height_px": 600,
          "creator_margin": 0.5,
          "id": "100_f18x24",
          "size_id": "100_f18x24",
        },
        {
          "cart": {
            "ilikemaps":
            {
              //   "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzIyNzMwNw==",
              "variant_id": "gid://shopify/ProductVariant/38147787227307",
              "name": "Framed 24x36 in.",
              "cost": 280
            },
            "cartoboutique": {
              "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMwMzk5MDc3MTgyNw==",
              "name": "24x36 po. encadré",
              "cost": 350
            }
          },
          names: {
            "en": "Framed 24x36 in.",
            "fr": "24x36 po. encadré"
          },
          //  "variant_id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODE0Nzc4NzIyNzMwNw==",
          "variant_id": "gid://shopify/ProductVariant/38147787227307",
          "size_id": "100_f24x36",
          "width_px": 400,
          "height": 36,
          "creator_margin": 0.75,
          "height_px": 600,
          "order": 20,
          "width": 24
        }

      ])
  })

  local_styles = new Observable(sub => {
    sub.next([
      {
        "scale_factor": 0.5,
        "style_in_production": 1,
        "title_e_size": 0.08,
        "bg_color": "#fff",
        "name": "Art Deco",
        "style_mb_id": "cix83au3r004p2qp42lghzqoj",
        "font_weight": 500,
        "bg_style": "#fff",
        "title_fill_bottom": "true",
        "title_box_border_style": "none",
        "baseline_adjustment": 0,
        "subtitle_line_height": 0.2,
        "font": "css/reb.ttf",
        "letter_spacing": 0.004,
        "title_w": 1,
        "title_padding": 0.05,
        "subtitle_hide": false,
        "style_id": 82,
        "basic_subtitle_size": 0.4,
        "font_color": "#000",
        "thumbnail_url": "assets/art-deco.jpg",
        "font_family": "serenity",
        "title_y_pos": 1
      },
      {
        "bg_color": "#fff",
        "title_fill_bottom": "true",
        "title_box_border_style": "none",
        "title_w": 1,
        "font_weight": 200,
        "title_e_size": 0.08,
        "subtitle_w_ratio": 0.5,
        "style_in_production": 1,
        "style_mb_id": "ciubl79g1002t2ipl51il5zhc",
        "subtitle_line_height": 0.3,
        "font_color": "#000",
        "title_box_double_border_size": 0,
        "style_id": 81,
        "baseline_adjustment": 0,
        "font": "css/rel.ttf",
        "max_title_w_ratio": 0.7,
        "font_family": "serenity",
        "letter_spacing": 0.006,
        "basic_subtitle_size": 0.4,
        "title_y_pos": 1,
        "thumbnail_url": "assets/bwpro.jpg",
        "name": "Black & White Pro",
        "scale_factor": 0.5,
        "title_padding": 0.05,
        "bg_style": "#fff",
        "max_title_h_ratio": 0.08
      },
      {
        "title_w": 0,
        "font": "css/rel.ttf",
        "baseline_adjustment": 0.1,
        "style_in_production": 1,
        "name": "Adventure",
        "title_box_border_style": "2px solid black",
        "font_weight": 600,
        "bg_style": "rgb(245, 242, 240)",
        "font_color": "#000",
        "title_fill_bottom": "true",
        "title_e_size": 0.05,
        "scale_factor": 0.5,
        "subtitle_line_height": 0.25,
        "title_y_pos": 0.97,
        "title_box_double_border_size": 0,
        "basic_subtitle_size": 0.4,
        "title_box_border": 2,
        "font_family": "novecento-slab",
        "title_padding": 0.05,
        "style_mb_id": "ckkpovo5a27kp17qqhhiatofm",
        "style_id": 87,
        "thumbnail_url": "assets/adventure.jpg",
        "letter_spacing": 0
      },
      {
        "baseline_adjustment": 0,
        "font": "css/rel.ttf",
        "style_in_production": 1,
        "style_id": 88,
        "title_e_size": 0.07,
        "thumbnail_url": "assets/creator_blue.jpg",
        "title_box_double_border_size": 0,
        "bg_color": "#fff",
        "name": "Creator Blue",
        "scale_factor": 0.5,
        "title_y_pos": 1,
        "bg_style": "#fff",
        "title_w": 1,
        "basic_subtitle_size": 0.4,
        "title_fill_bottom": "true",
        "font_family": "serenity",
        "title_box_border_style": "none",
        "style_mb_id": "cj1f4ym0700322rtcn4x5vflw",
        "title_padding": 0.05,
        "subtitle_line_height": 0.25,
        "font_weight": 200,
        "letter_spacing": 0.006,
        "font_color": "#455678"
      },
      {
        "bg_style": "#DAC590",
        "title_box_border": 1,
        "basic_subtitle_size": 0.5,
        "style_mb_id": "ciz2crif600352ss2kxfawg2k",
        "title_w": 0,
        "font": "css/reb.ttf",
        "title_y_pos": 0.97,
        "font_family": "raleway",
        "bg_file": "assets/large-vintage-bg1.png",
        "style_id": 84,
        "max_title_w_ratio": 0.7,
        "style_in_production": 1,
        "subtitle_line_height": 0.25,
        "font_weight": 800,
        "font_color": "#000",
        "thumbnail_url": "assets/vintage-2.jpg",
        "title_box_double_border_style": "1px solid black",
        "subtitle_w_ratio": 0.5,
        "title_e_size": 0.05,
        "baseline_adjustment": 0,
        "title_box_double_border_bg": "#DAC590",
        "scale_factor": 0.5,
        "title_box_double_border_size": 0.08,
        "max_title_h_ratio": 0.05,
        "letter_spacing": 0,
        "title_box_double_border": 1,
        "title_fill_bottom": "false",
        "title_padding": 0.05,
        "name": "Vintage 2",
        "title_box_border_style": "1px solid black"
      },
      {
        "font": "css/reb.ttf",
        "bg_file": "assets/large-vintage-bg1.png",
        "title_padding": 0.05,
        "name": "Vintage 1",
        "title_e_size": 0.05,
        "baseline_adjustment": 0,
        "basic_subtitle_size": 0.5,
        "style_id": 83,
        "title_fill_bottom": "false",
        "max_title_h_ratio": 0.05,
        "font_family": "raleway",
        "title_box_double_border_bg": "rgb(230 208 158)",
        "title_box_double_border_size": 0.08,
        "title_y_pos": 0.97,
        "title_w": 0,
        "bg_style": "rgb(230 208 158)",
        "font_color": "#000",
        "style_in_production": 1,
        "subtitle_w_ratio": 0.5,
        "max_title_w_ratio": 0.7,
        "title_box_double_border_style": "1px solid black",
        "letter_spacing": 0,
        "title_box_border_style": "1px solid black",
        "font_weight": 800,
        "title_box_double_border": 1,
        "subtitle_line_height": 0.25,
        "thumbnail_url": "assets/vintage-1.jpg",
        "scale_factor": 0.5,
        "style_mb_id": "ckiyznhiu0v7419k3i1py1xzj",
        "title_box_border": 1
      }
    ]


    );
  })

}
