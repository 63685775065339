// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDiTl1f5Q03MAE2onLzh2kDUIMTO7LWh4I",
    authDomain: "creator2-f70e9.firebaseapp.com",
    projectId: "creator2-f70e9",
    storageBucket: "creator2-f70e9.appspot.com",
    messagingSenderId: "897522417306",
    appId: "1:897522417306:web:58688cf6c67f1fa2f36923"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
