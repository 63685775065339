import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-email-input-post',
  templateUrl: './email-input-post.component.html',
  styleUrls: ['./email-input-post.component.css']
})
export class EmailInputPostComponent implements OnInit {

  @Input() displays: any;
  @Input() lang: string;
  @Output() DisplayEvent = new EventEmitter();
  ts: any;
  ts_source = {
    en: {
      h4: "Your map creation is being generated",
      p1: "Please allow 5 to 10 minutes to receive your preview file.",
      p2: "You'll receive your preview from the address info@ilikemaps.com. Be certain to white-list this email address and/or check your spam folder",
      p3: "You can create new maps in the meantime!",
      ok: "OK!"
    },
    fr: {
      h4: "Votre création cartographique est en train d'être générée",
      p1: "Merci de patienter 5 à 10 minutes avant de recevoir votre aperçu.",
      p2: "Vous recevrez l'aperçu de la part de l'adresse info@ilikemaps.com. Soyez certains d'autoriser cette adresse et vérifiez votre boîte SPAM.",
      p3: "Vous pouvez créer d'autres cartes en attendant!",
      ok: "OK!"
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.ts = this.ts_source[this.lang];
  }

  close = () => {
    var tmp_displays = this.displays;
    tmp_displays.email_input_post = false;
    this.setDisplays(tmp_displays);
  }

  setDisplays = (display_obj): void => {
    this.DisplayEvent.emit(display_obj);
  }

}
