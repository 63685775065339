import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faBars, faBackward, faPen, faShoppingCart, faImages } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  constructor() { }

  @Input() getDisplays: any;
  @Input() checkout: any;
  @Input() lang: string;
  @Output() DisplayEvent = new EventEmitter();

  ts: any;
  ts_source = {
    en: {
      site_title: "Custom Map Creator",
      map_editor: "Map Creator",
      map_gallery: "Map Gallery",
      return_to_ilm: "Return to iLikeMaps",
      cart: "Cart"
    },
    fr: {
      site_title: "Créateur de Cartes",
      map_editor: "Créateur de carte",
      map_gallery: "Gallerie",
      return_to_ilm: "Retour à iLikeMaps",
      cart: "Panier"
    }
  }

  //show_back_to_gallery: boolean;
  displays: any = {};
  faBars = faBars;
  faBackWard = faBackward;
  faPen = faPen;
  faShoppingCart = faShoppingCart;
  faImages = faImages;
  show_mobile_menu = false;

  ngOnInit(): void {
    this.displays.show_back_to_gallery = false;
    this.getDisplays.subscribe(event => {
      this.displays = event;
    });
    this.ts = this.ts_source[this.lang];

  }

  close_top_bar = () => {
    this.DisplayEvent.emit({ change: "relative", top_bar: false });
  }

  open_cart = () => {
    this.DisplayEvent.emit({ change: "relative", cart: true });
  }

  toggle_menu_bar = () => {
    this.show_mobile_menu = !this.show_mobile_menu;
  }

  go_to_gallery = () => {
    this.DisplayEvent.emit({
      map_editor: false,
      gallery: true,
      email_input: false,
      cart: false,
      processing: false,
      splash: false,
      email_input_post: false,
      back_to_gallery_bar: true,
      processing_message: "",
      top_bar: true
    });
  }
  go_to_map_editor = () => {
    this.DisplayEvent.emit({
      map_editor: true,
      gallery: false,
      email_input: false,
      cart: false,
      processing: false,
      splash: false,
      email_input_post: false,
      back_to_gallery_bar: true,
      processing_message: "",
      top_bar: true
    });

  }

}
