<div class="grid-x">
    <div class="cell top_bar">
        <div class="grid-x">
            <div class="cell small-10 large-7 top_bar_title_div">
                <a (click)="go_to_map_editor()"><img src="assets/logo_web.png" class="logo"
                        (click)="go_to_map_editor()" /></a>
                <h2><a (click)="go_to_map_editor()">{{ts.site_title}}</a></h2>
            </div>
            <div class="cell large-5 show-for-large">
                <ul class="menu align-right">
                    <li *ngIf="displays.back_to_gallery_bar && !displays.map_editor"><a (click)="go_to_map_editor()">
                            <fa-icon [icon]="faPen">
                            </fa-icon> {{ts.map_editor}}
                        </a></li>
                    <li *ngIf="displays.back_to_gallery_bar && !displays.gallery"><a (click)="go_to_gallery()">
                            <fa-icon [icon]="faImages">
                            </fa-icon> {{ts.map_gallery}}
                        </a>
                    </li>
                    <!--<li><a href="https://ilikemaps.com" target="_blank">
                            <fa-icon [icon]="faBackWard">
                            </fa-icon> {{ts.return_to_ilm}}
                        </a></li>-->
                    <li *ngIf="checkout"><a (click)="open_cart()">
                            <fa-icon [icon]="faShoppingCart">
                            </fa-icon> {{ts.cart}} <span *ngIf="checkout.itemCount >0">({{checkout.itemCount}})</span>
                        </a>
                    </li>
                </ul>
                <button class="close-button close-button-large" aria-label="Close alert" type="button" data-close
                    (click)="close_top_bar()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="cell medium-2 show-for-medium hide-for-large">
                <button class="close-button" aria-label="Close alert" type="button" data-close
                    (click)="close_top_bar()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="cell small-2 hide-for-medium align-right">
                <a (click)="toggle_menu_bar()" class="hamburger">
                    <fa-icon [icon]="faBars">
                    </fa-icon>
                </a>
            </div>
            <div class="cell show-for-medium hide-for-large">
                <ul class="menu">

                    <li *ngIf="displays.back_to_gallery_bar && !displays.map_editor"><a (click)="go_to_map_editor()">
                            <fa-icon [icon]="faPen">
                            </fa-icon> {{ts.map_editor}}
                        </a></li>
                    <li *ngIf="displays.back_to_gallery_bar && !displays.gallery"><a (click)="go_to_gallery()">
                            <fa-icon [icon]="faImages">
                            </fa-icon> {{ts.map_gallery}}
                        </a>
                    </li>
                    <!--<li><a href="https://ilikemaps.com" target="_blank">
                        <fa-icon [icon]="faBackWard">
                        </fa-icon> {{ts.return_to_ilm}}
                    </a></li>-->
                    <li *ngIf="checkout"><a (click)="open_cart()">
                            <fa-icon [icon]="faShoppingCart">
                            </fa-icon> {{ts.cart}} <span *ngIf="checkout.itemCount >0">({{checkout.itemCount}})</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="cell hide-for-medium" *ngIf="show_mobile_menu">
                <ul class="menu">

                    <li *ngIf="displays.back_to_gallery_bar && !displays.map_editor"><a (click)="go_to_map_editor()">
                            <fa-icon [icon]="faPen">
                            </fa-icon> {{ts.map_editor}}
                        </a></li>
                    <li *ngIf="displays.back_to_gallery_bar && !displays.gallery"><a (click)="go_to_gallery()">
                            <fa-icon [icon]="faImages">
                            </fa-icon> {{ts.map_gallery}}
                        </a>
                    </li>
                    <!--<li><a href="https://ilikemaps.com" target="_blank">
                        <fa-icon [icon]="faBackWard">
                        </fa-icon> {{ts.return_to_ilm}}
                    </a></li>-->
                    <li *ngIf="checkout"><a (click)="open_cart()">
                            <fa-icon [icon]="faShoppingCart">
                            </fa-icon> {{ts.cart}} <span *ngIf="checkout.itemCount >0">({{checkout.itemCount}})</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>