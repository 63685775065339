<div id="background_image" class="background_image show-for-medium wrapper"></div>
<div class="lds-dual-ring" *ngIf="displays.processing"></div>
<div class="processing_message_parent" *ngIf="displays.processing">
    <div class="processing_message">{{displays.processing_message}}</div>
</div>
<div class="hamburger show-for-medium" *ngIf="!displays.top_bar && !displays.splash"
    (click)="receiveToggleDisplay({change: 'relative', top_bar: true})">
    <a (click)="receiveToggleDisplay({change: 'relative', top_bar: true})">
        <fa-icon [icon]="faBars" (click)="receiveToggleDisplay({change: 'relative', top_bar: true})">
        </fa-icon>
    </a>
</div>
<div id="wrapper" class='grid-x wrapper'>
    <!--class='grid-x'-->
    <div class="cell">
        <app-top-bar [hidden]="!displays.top_bar" (DisplayEvent)="receiveToggleDisplay($event)"
            [getDisplays]="displaysSubject" [checkout]="checkout" [lang]="lang"></app-top-bar>
        <app-splash [hidden]="!displays.splash" (DisplayEvent)="receiveToggleDisplay($event)" [gMapLoader]="gMapLoader"
            (CityQueryEvent)="receiveCityQuery($event)" [lang]="lang"></app-splash>
        <app-ui [hidden]="!displays.map_editor" (DisplayEvent)="receiveToggleDisplay($event)"
            [getDisplays]="displaysSubject" (CartEvent)="cartEvent($event)" [checkout]="checkout"
            [gMapLoader]="gMapLoader" [newCityQuery]="newCityQuery" [previewEmailEntered]="previewEmailEntered"
            [lang]="lang" [cart_currency]="cart_currency" [cart_country]="cart_country">
        </app-ui>
        <app-gallery *ngIf="displays.gallery" (DisplayEvent)="receiveToggleDisplay($event)" [email_hash]="email_hash"
            (CartEvent)="cartEvent($event)" [lang]="lang" [cart_currency]="cart_currency" [cart_country]="cart_country">
        </app-gallery>
        <app-cart [hidden]="!displays.cart" (DisplayEvent)="receiveToggleDisplay($event)"
            (CartEvent)="cartEvent($event)" [checkout]="checkout" [displays]="displays" [lang]="lang">
        </app-cart>
        <app-email-input *ngIf="displays.email_input" (EmailEntered)="receiveEmailEntered($event)"
            (DisplayEvent)="receiveToggleDisplay($event)" [displays]="displays"
            [previewEmailEntered]="previewEmailEntered" [lang]="lang">
        </app-email-input>
        <app-email-input-post [hidden]="!displays.email_input_post" (DisplayEvent)="receiveToggleDisplay($event)"
            [displays]="displays" [lang]="lang"></app-email-input-post>

    </div>
</div>